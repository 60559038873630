<template>
    <div>
        <b-container>
            <section v-if="getAgencyGeneric.length == 0 && loader" class="event-details mb-3">
                <Spinner size="medium" line-fg-color="#e91e63" />
            </section>
            <section v-else class="agency">
                <b-row>
                    <b-col cols="12" lg="8">
                        <div class="sector sector--overflow">
                            <div v-if="getAgency.ribbon" class="custom-ribbon custom-ribbon--right">
                                <span class="custom-ribbon__text custom-ribbon__text--blue">Featured</span>
                            </div>
                            <b-row>
                                <b-col cols="12" md="3">
                                    <div class="agency__image" v-lazy:background-image="getAgency.url"></div>
                                </b-col>
                                <b-col cols="12" md="9">
                                    <h2 class="agency__title">{{ getAgency.name }}</h2>
                                    <span v-if="getAgency.rating" :id="getAgency.id" class="agency__star-tooltip"><star-rating :star-size="18" border-color="transparent" :show-rating="false" :rating="getAgency.rating" :increment="0.01"  :read-only="true"></star-rating>
                                        <b-tooltip :target="getAgency.id">
                                            Rating: {{ getAgency.rating }}
                                        </b-tooltip>
                                    </span>
                                    <span v-else class="agency__star-tooltip"></span>
                                    <h3 class="agency__subtitle">Profile summary</h3>
                                    <span class="agency__info"><span class="agencies__star-tooltip" v-b-tooltip title="Minimum project size"><simple-line-icons icon="tag" size="small" color="#504e70" /></span>Minimum project size: {{ getAgency.project.text }}</span>
                                    <span class="agency__info"><span class="agencies__star-tooltip" v-b-tooltip title="Employees"><simple-line-icons icon="people" size="small" color="#504e70" /></span>Employees: {{ getAgency.employees.text }}</span>
                                    <span class="agency__info"><span class="agencies__star-tooltip" v-b-tooltip title="Founded"><simple-line-icons icon="flag" size="small" color="#504e70" /></span>Founded: {{ getAgency.founded }}</span>
                                    <span v-if="getAgency.leader" class="agency__info"><span class="agencies__star-tooltip" v-b-tooltip title="Market leader"><simple-line-icons icon="diamond" size="small" color="#504e70" /></span>Market leader</span>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-if="getAgency.stability" class="sector">
                            <h3 class="agency__subtitle">Company profile</h3>
                            <b-row>
                                <b-col cols="12" md="4">
                                    <div id="chartStability">
                                        <apexchart type=radialBar height=250 :options="chartStability" :series="[getAgency.stability]" />
                                    </div>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <div id="chartQuality">
                                        <apexchart type=radialBar height=250 :options="chartQuality" :series="[getAgency.quality]" />
                                    </div>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <div id="chartReliability">
                                        <apexchart type=radialBar height=250 :options="chartReliability" :series="[getAgency.reliability]" />
                                    </div>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <div id="chartFlexibility">
                                        <apexchart type=radialBar height=250 :options="chartFlexibility" :series="[getAgency.flexibility]" />
                                    </div>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <div id="chartTransparency">
                                        <apexchart type=radialBar height=250 :options="chartTransparency" :series="[getAgency.transparency]" />
                                    </div>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <div id="chartIntegrity">
                                        <apexchart type=radialBar height=250 :options="chartIntegrity" :series="[getAgency.integrity]" />
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-if="getAgency.performance" class="sector">
                            <h3 class="agency__subtitle">Performance management</h3>
                            <b-row>
                                <b-col cols="12">
                                    <div id="chartPerformance">
                                        <apexchart type=bar height=350 :options="chartPerformance" :series="[{
                                            name: 'Strong performance',
                                            data: [getAgency.performance.strong[0], getAgency.performance.strong[1], getAgency.performance.strong[2], getAgency.performance.strong[3], getAgency.performance.strong[4]]
                                            }, {
                                            name: 'High performance',
                                            data: [getAgency.performance.high[0], getAgency.performance.high[1], getAgency.performance.high[2], getAgency.performance.high[3], getAgency.performance.high[4]]
                                            }, {
                                            name: 'Stable performance',
                                            data: [getAgency.performance.stable[0], getAgency.performance.stable[1], getAgency.performance.stable[2], getAgency.performance.stable[3], getAgency.performance.stable[4]]
                                            }]" />
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-if="getAgency.performance" class="sector">
                            <h3 class="agency__subtitle">Brand management</h3>
                            <b-row>
                                <b-col cols="12">
                                    <div id="chartBranding">
                                        <apexchart type=line height=350 :options="chartBranding"
                                        :series="[
                                            {
                                                name: 'Brand Recognition',
                                                data: [getAgency.recognition[0], getAgency.recognition[1], getAgency.recognition[2], getAgency.recognition[3], getAgency.recognition[4], getAgency.recognition[5], getAgency.recognition[6]]
                                            },
                                            {
                                                name: 'Brand Awareness',
                                                data:[getAgency.awareness[0], getAgency.awareness[1], getAgency.awareness[2], getAgency.awareness[3], getAgency.awareness[4], getAgency.awareness[5], getAgency.awareness[6]]
                                            }
                                        ]" />
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="sector">
                            <h3 class="agency__subtitle">Description</h3>
                            <p v-html="getAgency.desc" class="agency__text"></p>
                        </div>
                        <div class="sector">
                            <h3 class="agency__subtitle">Services</h3>
                            <b-row>
                                <b-col v-if="getAgency.services.advertising" cols="12" md="4">
                                   <span class="agency__service"><v-icon name="check-circle" scale="1" />Advertising</span>
                                </b-col>
                                <b-col v-if="getAgency.services.digital" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />Digital marketing</span>
                                </b-col>
                                <b-col v-if="getAgency.services.branding" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />Branding</span>
                                </b-col>
                                <b-col v-if="getAgency.services.social" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />Social media marketing</span>
                                </b-col>
                                <b-col v-if="getAgency.services.webDev" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />Web development</span>
                                </b-col>
                                <b-col v-if="getAgency.services.mobile" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />Mobile app development</span>
                                </b-col>
                                <b-col v-if="getAgency.services.commerce" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />eCommerce</span>
                                </b-col>
                                <b-col v-if="getAgency.services.data" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />Big Data</span>
                                </b-col>
                                <b-col v-if="getAgency.services.emailMarketing" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />E-mail marketing</span>
                                </b-col>
                                <b-col v-if="getAgency.services.eventMarketing" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />Event marketing</span>
                                </b-col>
                                <b-col v-if="getAgency.services.cloud" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />Cloud solutions</span>
                                </b-col>
                                <b-col v-if="getAgency.services.seo" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />SEO</span>
                                </b-col>
                                <b-col v-if="getAgency.services.graphic" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />Graphic design</span>
                                </b-col>
                                <b-col v-if="getAgency.services.print" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />Print design</span>
                                </b-col>
                                <b-col v-if="getAgency.services.video" cols="12" md="4">
                                    <span class="agency__service"><v-icon name="check-circle" scale="1" />Video production</span>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="4">
                        <div class="sector">
                            <div class="agency__element mb-0">
                                <h3 class="agency__subtitle">Contact</h3>
                                <span v-if="getAgency.email" class="agency__info"><simple-line-icons icon="envelope" size="small" color="#504e70" /><a class="agency__link" :href="'mailto:'+ getAgency.email">{{ getAgency.email }}</a></span>
                                <span v-if="getAgency.phone" class="agency__info"><simple-line-icons icon="phone" size="small" color="#504e70" />{{ getAgency.phone }}</span>
                                <span v-if="getAgency.website" class="agency__info"><simple-line-icons icon="globe" size="small" color="#504e70" /> <a class="agency__link" :href="getAgency.website" target="_blank">website</a></span>
                                <span class="agency__info"><simple-line-icons icon="locationPin" size="small" color="#504e70" />{{ getAgency.address}}, {{ getAgency.city}}, {{ getAgency.country.text}}</span>
                            </div>
                        </div>
                        <div class="sector">
                            <div class="agency__element mb-0">
                                <h3 class="agency__subtitle">Map location</h3>
                                <div class="agency__map">
                                    <l-map :zoom="zoom" :center="[getAgency.lat, getAgency.lng]" :options="{zoomControl: false}">
                                    <l-tile-layer
                                    :url="url"
                                    layer-type="base"></l-tile-layer>
                                    <l-marker :lat-lng="[getAgency.lat, getAgency.lng]">
                                        <l-popup><b>{{ getAgency.name}}</b><br> {{ getAgency.address }},<br> {{ getAgency.city }}, {{ getAgency.country.text }}</l-popup>
                                    </l-marker>
                                    <l-control-zoom position="topright"></l-control-zoom>
                                    </l-map>
                                </div>
                            </div>
                        </div>
                        <!-- <AsideAdvertisement /> -->
                        <!-- <div class="sector">
                            <div class="agency__element mb-0">
                                <router-link to="/statistics" class="categories__link">
                                    <div class="categories__item mb-0">
                                        <v-icon name="gem" scale="7" />
                                        <h3 class="categories__title">Explore our Leaders Matrix</h3>
                                    </div>
                                </router-link>
                            </div>
                        </div> -->
                    </b-col>
                </b-row>
            </section>
        </b-container>
        <LatestAgenciesList />
    </div>
</template>

<script>
import LatestAgenciesList from '@/components/LatestAgenciesList.vue'
import AsideAdvertisement from '@/components/AsideAdvertisement.vue'
import SimpleLineIcons from 'vue-simple-line'
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/gem'
import 'vue-awesome/icons/check-circle'
import StarRating from 'vue-star-rating'
import VueApexCharts from 'vue-apexcharts'
import Spinner from 'vue-simple-spinner'
import { mapGetters } from 'vuex'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControlZoom
} from 'vue2-leaflet'
export default {
  components: {
    'v-icon': Icon,
    LatestAgenciesList,
    SimpleLineIcons,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControlZoom,
    StarRating,
    'apexchart': VueApexCharts,
    Spinner,
    AsideAdvertisement
  },
  data: function () {
    return {
      loader: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16,
      center: [52.163308, 21.084495],
      chartStability: {
        colors: ['#e91e63'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%'
            }
          }
        },
        labels: ['Stability']
      },
      chartQuality: {
        colors: ['#50acfc'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%'
            }
          }
        },
        labels: ['Quality']
      },
      chartReliability: {
        colors: ['#54d843'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%'
            }
          }
        },
        labels: ['Reliability']
      },
      chartFlexibility: {
        colors: ['#ffa22b'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%'
            }
          }
        },
        labels: ['Flexibility']
      },
      chartTransparency: {
        colors: ['#26c6da'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%'
            }
          }
        },
        labels: ['Transparency']
      },
      chartIntegrity: {
        colors: ['#a797d4'],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%'
            }
          }
        },
        labels: ['Integrity']
      },
      chartPerformance: {
        chart: {
          stacked: true,
          stackType: '100%'
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '50%'
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: ['Creativity & Innovation', 'Campaign Strategy', 'Due Diligence', 'Technical Competency', 'Social Awareness']
        },
        colors: ['#82C12F', '#BDDB63', '#FFD034'],
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.75
            }
          }
        },
        tooltip: {
          enabled: false
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: -20
        }
      },
      chartBranding: {
        chart: {
          shadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1
          },
          toolbar: {
            show: true
          }
        },
        colors: ['#13334C', '#FD5F00'],
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        markers: {
          size: 6
        },
        yaxis: {
          title: {
            text: 'Brand Awareness & Brand Recognition growth'
          },
          min: 5,
          max: 40
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: -20
        }
      }
    }
  },
  computed: {
    getAgencyGeneric () {
      return this.$store.getters.getAgency
    },
    getAgency () {
      return this.$store.getters.getAgency[0]
    }
  },
  beforeCreate () {
    this.$store.dispatch('getAgency', { id: this.$route.params.id })
  },
  created () {
    setTimeout(function () { this.loader = false }.bind(this), 5000)
  }
}
</script>
